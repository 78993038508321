<template>
  <HXContentCard title="Registro GPS">
    <div class="px-5 py-3">
      <div class="row" style="gap: 1rem">
        <div class="col-12 col-md-10">
          <!-- linar-style cursor glow -->
          <CardTable :data="dataCards" />
        </div>
        <!-- btn new Gps -->
        <div class="col-12 col-md">
          <BtnAddCardTable text="Nuevo GPS" url="gps/gps_agregar" />
        </div>
      </div>
    </div>
    <!-- table registroGPS -->
    <div class="px-4 mt-3">
      <table
        id="datatable_registroGPS"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>Imei</th>
            <th>Marca</th>
            <th>Modelo</th>
            <th>Fecha Compra</th>
            <th>Detalles</th>
            <th>Asignado</th>
            <th>Activo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaItems" :key="item.id">
            <!-- imei -->
            <td>
              {{ item.imei }}
            </td>
            <!-- marca -->
            <td>
              {{ item.marca }}
            </td>
            <!-- modelo -->
            <td>{{ item.modelo }}</td>
            <!-- fecha compra -->
            <td>{{ item.fechaCompra | formatDate }}</td>
            <!-- mas detalles -->
            <td>
              <div class="d-flex justify-content-center">
                <vs-button icon v-b-modal.detailsGps @click="filterData(item)">
                  <i class="fas fa-eye"></i
                ></vs-button>
              </div>
            </td>
            <!-- asignado -->
            <td>
              <h4>
                <b-badge
                  :variant="`${
                    item.gpsActivo && item.gpsAsignado
                      ? 'success'
                      : !item.gpsActivo
                      ? 'danger'
                      : 'info'
                  } `"
                >
                  <i
                    :class="`fas ${
                      item.gpsActivo && item.gpsAsignado
                        ? 'fa-car'
                        : !item.gpsActivo
                        ? 'fa-circle-xmark'
                        : 'fa-bolt'
                    }`"
                  ></i>
                  {{
                    item.gpsActivo && item.gpsAsignado
                      ? `${item.placaVehiculo}`
                      : !item.gpsActivo
                      ? "Inactivo"
                      : "Disponible"
                  }}
                </b-badge>
              </h4>
            </td>
            <!-- activo -->
            <td>
              <template>
                <div class="d-flex align-items-center justify-content-center">
                  <VueToggles
                    v-b-tooltip.hover
                    title="Activar/Inactivar"
                    height="20"
                    width="50"
                    :value="item.gpsActivo"
                    :disabled="item.gpsAsignado"
                    @click="toggleActivo(index, item)"
                  />
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- modal ver mas datalles -->
    <b-modal id="detailsGps" title="Detalles GPS" size="lg" hide-footer>
      <Gps_detail
        :gps="filterGps"
        :updateDataAssign="updateDataAssign"
        :updateDataLiberate="updateDataLiberate"
      />
    </b-modal>
  </HXContentCard>
</template>

<script>
import { core } from "../../../config/pluginInit";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import Gps_detail from "./gps_detail.vue";
const Swal = require("sweetalert2");
export default {
  name: "gps",
  components: {
    CardTable,
    BtnAddCardTable,
    Gps_detail,
  },
  data() {
    return {
      persona: {},
      dataTableInstance: null,
      isConfirmActivoSingleMap: {},
      filterGps: {
        id: null,
        empresaId: null,
        imei: "",
        marca: "",
        modelo: "",
        serie: "",
        fechaCompra: "",
        proveedor: "",
        direccionProveedor: "",
        telefonoProveedor: "",
        paisProveedor: "",
        ciudadProveedor: "",
        fechaInstalacionGps: "",
        nombreCompletoInstalador: "",
        ciudadInstalador: "",
        direccionInstalador: "",
        telefonoInstalador: "",
        placaVehiculo: "",
        vehiculoId: null,
        gpsAsignado: false,
        gpsActivo: false,
        numeroSimCard: "",
        empresaSimCard: "",
        fechaActivacionSimCard: "",
        tipoPlanSimCard: "",
        ajusteHora: null,
        empresa: {
          /* Objeto de datos de la empresa */
        },
      },
      listaItems: [
        {
          id: 1,
          empresaId: 123,
          imei: "123456789012345",
          marca: "Marca Ejemplo",
          modelo: "Modelo Ejemplo",
          serie: "S12345",
          fechaCompra: "2023-01-15",
          proveedor: "Proveedor XYZ",
          direccionProveedor: "Calle Principal 123",
          telefonoProveedor: "+1234567890",
          paisProveedor: "País Ejemplo",
          ciudadProveedor: "Ciudad Ejemplo",
          fechaInstalacionGps: "2023-02-01",
          nombreCompletoInstalador: "Juan Pérez",
          ciudadInstalador: "Otra Ciudad",
          direccionInstalador: "Calle Secundaria 456",
          telefonoInstalador: "+987654321",
          placaVehiculo: "ABC123",
          vehiculoId: null,
          gpsAsignado: true,
          gpsActivo: true,
          numeroSimCard: "9876543210",
          empresaSimCard: "Empresa de Telecom",
          fechaActivacionSimCard: "2023-02-01",
          tipoPlanSimCard: "Plan Ilimitado",
          ajusteHora: null,
          empresa: {
            /* Objeto de datos de la empresa */
          },
        },
      ],
      dataCards: [
        {
          title: "Asignados",
          value: () =>
            this.listaItems.filter((gps) => gps.gpsActivo && gps.gpsAsignado)
              .length,
          iconName: "map-location-dot",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            // this.dataTableInstance.search("Activo").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            // this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Disponibles",
          value: () =>
            this.listaItems.filter((gps) => gps.gpsActivo && !gps.gpsAsignado)
              .length,
          iconName: "bolt",
          color: "rgba(71, 136, 255, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Disponible").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Inactivos",
          value: () => this.listaItems.filter((gps) => !gps.gpsActivo).length,
          iconName: "circle-xmark",
          color: "rgba(223, 18, 46, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Inactivo").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      tipoAccion: {
        liberar: "liberado",
        asignar: "asignado",
        inactivar: "inactivado",
        activar: "activado",
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    let { empresa, rol } = this.persona;

    console.log(empresa.id);

    this.listaItems = [];

    if (rol.nivel >= 4) {
      await this.getData();
    } else {
      await this.getData(empresa.id);
    }
    core.index();

    if (window.$.fn.DataTable.isDataTable("#datatable_registroGPS")) {
      window.$("#datatable_registroGPS").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_registroGPS").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    async addLog(GpsId, PersonaId, VehiculoId, TipoAccion, Fecha) {
      try {
        let newLog = {
          GpsId,
          PersonaId,
          VehiculoId,
          TipoAccion,
          Fecha,
        };
        console.log(newLog, "newLog");
        let res = await this.$store.dispatch("hl_post", {
          path: "VehiculoGpsLog/",
          data: newLog,
        });
        console.log("add log...", res);
        if (res.success) {
          return res;
        }
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    updateDataLiberate(gpsId) {
      const gpsToUpdate = this.listaItems.find((gps) => gps.id === gpsId);
      if (gpsToUpdate) {
        // Si se encuentra el elemento, actualizar las propiedades
        gpsToUpdate.placaVehiculo = null;
        gpsToUpdate.vehiculoId = null;
        gpsToUpdate.gpsAsignado = false;
      } else {
        // Manejo de error si no se encuentra el elemento
        console.error(`No se encontró un elemento con el ID ${gpsId}`);
      }
    },
    updateDataAssign(gpsId, assignPlacaVehiculo, assignVehiculoId) {
      const gpsToUpdate = this.listaItems.find((gps) => gps.id === gpsId);
      if (gpsToUpdate) {
        // Si se encuentra el elemento, actualizar las propiedades
        gpsToUpdate.placaVehiculo = assignPlacaVehiculo;
        gpsToUpdate.vehiculoId = assignVehiculoId;
        gpsToUpdate.gpsAsignado = true;
      } else {
        // Manejo de error si no se encuentra el elemento
        console.error(`No se encontró un elemento con el ID ${gpsId}`);
      }
    },
    async toggleActiveGps(gpsId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "VehiculoRegistroImeiGPS/ToggleActive/" + gpsId,
        });
        console.log("toggleActiveGps...", res);
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async getData(empresaId = "") {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `VehiculoRegistroImeiGPS/ListByEmpresa/${empresaId}`,
        });
        console.log("list registrosImeiGps...", res);
        if (res.success) {
          this.listaItems = res.data;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    async toggleActivo(rowIndex, item) {
      const accion = item.gpsActivo ? "inactivar" : "activar";
      const mensajeAccion = item.gpsActivo
        ? "Los GPS inactivos no se pueden asignar a los vehículos!"
        : "Deseas activar este GPS para ser asignado a los vehículos?";
      const confirmButtonText = item.gpsActivo
        ? "Si, inactivar!"
        : "Si, activar!";
      const titleNotificacion = item.gpsActivo
        ? "GPS Inactivo!"
        : "GPS Activo!";

      const result = await Swal.fire({
        title: `Seguro de ${accion}?`,
        text: mensajeAccion,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "rgb(242, 19, 93)",
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        const response = await this.toggleActiveGps(item.id);

        if (response.success) {
          const tipoAccionLog = item.gpsActivo
            ? this.tipoAccion.inactivar
            : this.tipoAccion.activar;

          await this.addLog(
            item.id,
            this.persona.id,
            item.vehiculoId,
            tipoAccionLog,
            this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm")
          );

          this.$set(this.listaItems, rowIndex, {
            ...item,
            gpsActivo: !item.gpsActivo,
          });

          setTimeout(() => {
            this.dataTableInstance.row(rowIndex).invalidate().draw();
          }, 100);

          this.$vs.notification({
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: titleNotificacion,
          });
        }
      }
    },

    filterData(item) {
      console.log(item);
      this.filterGps = item;
    },
  },
  filters: {
    formatDate(dateString) {
      return dateString ? moment(dateString).format("MMM D, YYYY") : "";
    },
  },
};
</script>

<style scoped></style>
